import * as taiko from '@/assets/data/contracts/taiko.json';
import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: taiko.MerkleOrchard,
  multicall: '0xca11bde05977b3631167028862be2a173976ca11',
  authorizer: taiko.Authorizer,
  vault: taiko.Vault,
  weightedPoolFactory: taiko.WeightedPoolFactory,
  stablePoolFactory: taiko.StablePoolFactory,
  lidoRelayer: '',
  balancerHelpers: taiko.BalancerHelpers,
  batchRelayer: taiko.BalancerRelayer,
  veBAL: '',
  gaugeController: '',
  gaugeFactory: '',
  balancerMinter: '',
  tokenAdmin: '',
  veDelegationProxy: taiko.VotingEscrowDelegationProxy,
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  omniVotingEscrow: '',
  claimSubmission: '',
};

export default contracts;

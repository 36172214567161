import { TokenConstants } from '../types';

const tokens: TokenConstants = {
  Popular: {
    Symbols: ['XSGD', 'USDC'],
  },
  InitialSwapTokens: {
    input: '0x2c843abde5e92253d7f20f14b70e3cbe6672dadd', // XSGD
    output: '0x961a0ae05cc348bfd24ee49318f40d6701245f2d', // USDC
  },
  Addresses: {
    nativeAsset: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', // ETH
    wNativeAsset: '0x0be4cb1416e178177e7ef19dd199860e755213e7', // WETH
    WETH: '0x0be4cb1416e178177e7ef19dd199860e755213e7',
    BAL: '0xfe23f6b98a1ac54db889e8566f7a1aae83b9e9b3',
  },
};

export default tokens;

import { Protocol } from '@/composables/useProtocols';
import { CSP_ISSUE_POOL_IDS } from '@/constants/pool-lists/csp-issue';
import { PoolWarning, Pools } from '@/types/pools';
import { Network } from '../types';

const pools: Pools = {
  IdsMap: {
    staBAL:
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000063',
    bbAaveUSD: {
      v1: '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe',
      v2: '0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d',
      v3: '0xfebb0bbf162e64fb9d0dfe186e517d84c395f016000000000000000000000502',
    },
    veBAL: '0x5c6ee304399dbdb9c8ef030ab642b10820db8f56000200000000000000000014',
    veLIT: '0x9232a548dd9e81bac65500b5e0d918f8ba93675c000200000000000000000423',
    veUSH: '0xd689abc77b82803f22c49de5c8a0049cc74d11fd000200000000000000000524',
    veQi: '0x39eb558131e5ebeb9f76a6cbf6898f6e6dce5e4e0002000000000000000005c8',
    veGEM: '0x57766212638c425e9cb0c6d6e1683dda369c0fff000200000000000000000678',
  },
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [''],
  IncludedPoolTypes: [
    'Weighted',
    'Stable',
    'MetaStable',
    'LiquidityBootstrapping',
    'Investment',
    'StablePhantom',
    'ComposableStable',
    'FX',
    'EulerLinear',
    'Gyro2',
    'Gyro3',
    'GyroE',
  ],
  Stable: {
    AllowList: [
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000063', // staBAL3 (DAI-USD-USDC)
      '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56000000000000000000000066', // WBTC-renBTC-sBTC
      '0x9f19a375709baf0e8e35c2c5c65aca676c4c719100000000000000000000006e', // PAR-sEUR-EURS
      '0x32296969ef14eb0c6d29669c550d4a0449130230000200000000000000000080', // Lido Metastable
      '0x1e19cf2d73a72ef1332c882f20534b6519be0276000200000000000000000112', // Rocket Pool Metastable
      '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe', // Mainnet bb-a-USD
      '0x851523a36690bf267bbfec389c823072d82921a90002000000000000000001ed', // wstETH/WETH #2
      '0x3dd0843a028c86e0b760b1a76929d1c5ef93a2dd000200000000000000000249', // b-auraBAL-Stable (auraBal / 8020 BALETH)
      '0x2d011adf89f0576c9b722c28269fcb5d50c2d17900020000000000000000024d', // sdBAL Stable Pool (sdBAL / 8020 BALETH)
      '0x178e029173417b1f9c8bc16dcec6f697bc32374600000000000000000000025d', // Fiat DAO Stable Pool
      '0xf93579002dbe8046c43fefe86ec78b1112247bb80000000000000000000002bc', // USDD 3 pool
      '0xf3aeb3abba741f0eece8a1b1d2f11b85899951cb000200000000000000000351', // MAI stable pool
      '0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d', // bb-a-USD V2
      '0x5b3240b6be3e7487d61cd1afdfc7fe4fa1d81e6400000000000000000000037b', // DOLA/INV stable pool
      '0x2ba7aa2213fa2c909cd9e46fed5a0059542b36b00000000000000000000003a3', // TUSD/bbausd
      '0x6a9603e481fb8f2c09804ea9adab49a338855b900000000000000000000003a8', // TanukiX graviAURA Stable Pool
      '0x8e85e97ed19c0fa13b2549309965291fbbc0048b0000000000000000000003ba', // staked frax/rocket/wsteth
      '0xac976bb42cb0c85635644e8c7c74d0e0286aa61c0000000000000000000003cb', // fiat/bbausd
      '0xc9c5ff67bb2fae526ae2467c359609d6bcb4c5320000000000000000000003cc', // qeth/eth tranchess
      '0x4edcb2b46377530bc18bb4d2c7fe46a992c73e100000000000000000000003ec', // cbETH/wstETH
      '0x53bc3cba3832ebecbfa002c12023f8ab1aa3a3a0000000000000000000000411', // TUSD/bb-a-usd
      '0x4c8d2e60863e8d7e1033eda2b3d84e92a641802000000000000000000000040f', // FRAX/aave-usdc
      '0x9c6d47ff73e0f5e51be5fd53236e3f595c5793f200020000000000000000042c', // cbeth/wsteth stable
      '0xff4ce5aaab5a627bf82f4a571ab1ce94aa365ea6000200000000000000000426', // dola/usdc stable
      '0x384f67aa430376efc4f8987eabf7f3f84eb9ea5d00020000000000000000043d', // dola/cusd stable
      '0x8a34b5ad76f528bfec06c80d85ef3b53da7fc30000020000000000000000043e', // ankrETH/weth stable
      '0xb08885e6026bab4333a80024ec25a1a3e1ff2b8a000200000000000000000445', // rETH (stafi ETH)/weth
      '0x961764651931941f23cea5bab246607dc19ef224000200000000000000000444', // tetubal
      '0x831261f44931b7da8ba0dcc547223c60bb75b47f000200000000000000000460', // wUSDR/USDC stable pool
      '0x5aee1e99fe86960377de9f88689616916d5dcabe000000000000000000000467', // sfrxeth/wsteth/reth (v3)
      '0x50cf90b954958480b8df7958a9e965752f62712400000000000000000000046f', // bbeusd
      '0x133d241f225750d2c92948e464a5a80111920331000000000000000000000476', // dola/bbeusd
      '0x00c2a4be503869fa751c2dbcb7156cc970b5a8da000000000000000000000477', // euler-frax/euler-usdc
      '0x3dbb8d974b82e82ce79c20c0f5995f4f1f533ede000000000000000000000470', // zUSD-bb-e-USD
      '0x60683b05e9a39e3509d8fdb9c959f23170f8a0fa000000000000000000000489', // idle boosted
      '0x483006684f422a9448023b2382615c57c5ecf18f000000000000000000000488', // tusd euler
      '0x99c88ad7dc566616548adde8ed3effa730eb6c3400000000000000000000049a', // gearbox stable
      '0x20b156776114e8a801e9767d90c6ccccc8adf398000000000000000000000499', // yearn stable
      '0xdb3b48f27332c171869f2ae4160bc93a8eed347c00000000000000000000049b', // baoUSD USDC
      '0x779d01f939d78a918a3de18cc236ee89221dfd4e0000000000000000000004c7', // sturdy usd
      '0xcaa052584b462198a5a9356c28bce0634d65f65c0000000000000000000004db', // morpho usd
      '0x79c58f70905f734641735bc61e45c19dd9ad60bc0000000000000000000004e7', // stable3
      '0x793f2d5cd52dfafe7a1a1b0b3988940ba2d6a63d0000000000000000000004f8', // Bifrost vETH stableswap
      '0xfebb0bbf162e64fb9d0dfe186e517d84c395f016000000000000000000000502', // bb-a-usd v3
      '0xe0fcbf4d98f0ad982db260f86cf28b49845403c5000000000000000000000504', // bb-a-eth / wsteth
      '0x02d928e68d8f10c0358566152677db51e1e2dc8c00000000000000000000051e', // sweth/bb-a-weth
      '0x04248aabca09e9a1a3d5129a7ba05b7f17de768400000000000000000000050e', // bb-a-weth / qeth
      '0x9001cbbd96f54a658ff4e6e65ab564ded76a543100000000000000000000050a', // bb-a-weth / cbeth
      '0xbfce47224b4a938865e3e2727dc34e0faa5b1d82000000000000000000000527', // uniweth / weth
      '0x2e848426aec6dbf2260535a5bea048ed94d9ff3d000000000000000000000536', // wbeth/wsteth
      '0xec3626fee40ef95e7c0cbb1d495c8b67b34d398300000000000000000000053d', // uzd/bb-a-usd
      '0x7e9afd25f5ec0eb24d7d4b089ae7ecb9651c8b1f000000000000000000000511', // baoUSD LUSD
      '0x1a44e35d5451e0b78621a1b3e7a53dfaa306b1d000000000000000000000051b', // baoETH WETH
      '0xeb567dde03f3da7fe185bdacd5ab495ab220769d000000000000000000000548', // bb-a-weth/ankreth
      '0x20a61b948e33879ce7f23e535cc7baa3bc66c5a9000000000000000000000555', // R/DAI
      '0xfcf77141908aa22bfeac216123c5feb2531f373e00000000000000000000054a', // sWETH/rETH
      '0xf57c794f42da72b38c8f610ff3b5e8502e48cbde00000000000000000000055c', // DOLA/bb-a-usd
      '0xdfe6e7e18f6cc65fa13c8d8966013d4fda74b6ba000000000000000000000558', // ankrETH/wstETH
      '0x481c5fc05d63a58aa2f0f2aa417c021b5d419cb200000000000000000000056a', // reth/bb-a-weth
      '0xc5dc1316ab670a2eed5716d7f19ced321191f38200000000000000000000056e', // wstETH/morpho weth
      '0x156c02f3f7fef64a3a9d80ccf7085f23cce91d76000000000000000000000570', // vETH/WETH
      '0x0bbc7b78ff8453c40718e290b33f1d00ee67274e000000000000000000000563', // baoeth/beth
      '0x911fc393355fe415a667893d55fff6703c57c72e000200000000000000000574', // 50ETHx-50WETH
      '0xd8721e92ba0f8235b375e9ec9a7b697ec4e2d6c6000200000000000000000575', // 80BIDS-20WETH
      '0xbf2ef8bdc2fc0f3203b3a01778e3ec5009aeef3300000000000000000000058d', // R/bbsDAI
      '0x4cbde5c4b4b53ebe4af4adb85404725985406163000000000000000000000595', // ETHx/WETH
      '0x42ed016f826165c2e5976fe5bc3df540c5ad0af700000000000000000000058b', // wstETH-rETH-sfrxETH-BPT
      '0xc443c15033fcb6cf72cc24f1bda0db070ddd9786000000000000000000000593', // bb-a-USD
      '0x41503c9d499ddbd1dcdf818a1b05e9774203bf46000000000000000000000594', // wstETH-bb-a-WETH-BPT
      '0xd7edb56f63b2a0191742aea32df1f98ca81ed9c600000000000000000000058e', // B-wstETH/bb-ma3-weth
      '0x3fa8c89704e5d07565444009e5d9e624b40be813000000000000000000000599', // gho/lusd
      '0x9a172e1cb0e99f7e6dcc4c52e4655e8f337d5c0000000000000000000000059a', // gho/mai
      '0xc2b021133d1b0cf07dba696fd5dd89338428225b000000000000000000000598', // gho/bb-a-usd
      '0xe2d16b0a39f3fbb4389a0e8f1efcbecfb3d1e6e10000000000000000000005a7', // dusd-bb-a-usd
      '0x9b692f571b256140a39a34676bffa30634c586e100000000000000000000059d', // bb-i-USD
      '0xdd59f89b5b07b7844d72996fc9d83d81acc8219600000000000000000000059e', // uniETH-WETH
      '0xb54e6aadbf1ac1a3ef2a56e358706f0f8e320a0300000000000000000000059f', // vETH/WETH
      '0x4c81255cc9ed7062180ea99962fe05ac0d57350b0000000000000000000005a3', // cbETH-bb-a-WETH
      '0x1bd2f176a812e312077bca87e37c08432bb09f3e0000000000000000000005a1', // qETH-bb-a-WETH
      '0xae8535c23afedda9304b03c68a3563b75fc8f92b0000000000000000000005a0', // swETH-bb-a-WETH
      '0xe8c56405bc405840154d9b572927f4197d110de10000000000000000000005a4', // rETH/bbaWETH
      '0xe4e72f872c4048925a78e1e6fddac411c9ae348a0000000000000000000005bc', // 2BTC
      '0x93d199263632a4ef4bb438f1feb99e57b4b5f0bd0000000000000000000005c2', // wstETH-WETH-BPT
      '0xbe19d87ea6cd5b05bbc34b564291c371dae967470000000000000000000005c4', // GHO-3POOL-BPT
      '0xd6abbfd427398ea463b4f15f405624bab520db030000000000000000000005c6', // cbETH-wstETH-BPT
      '0xe7e2c68d3b13d905bbb636709cf4dfd21076b9d20000000000000000000005ca', // swETH-WETH-BPT
      '0xd5d99b7e9697ba8bb1da48f07ba81900c7572cea0000000000000000000005cc', // DUSD-3POOL-BPT
      '0x37b18b10ce5635a84834b26095a0ae5639dcb7520000000000000000000005cb', // ETHx-WETH-BPT
      '0x8353157092ed8be69a9df8f95af097bbf33cb2af0000000000000000000005d9', // GHO/USDT/USDC
      '0x6ae5a7857aad26722cb02cc168e38c52e0e4e45d0000000000000000000005dd', // TBY-feb1924-USDC-BPT
      '0x70d5e3234f6329c1d5a26796dcf4e109d69a34880000000000000000000005e7', // uniETH/wstETH/rETH
      '0x01536b22ea06e4a315e3daaf05a12683ed4dc14c0000000000000000000005fc', // e-cs-kp-usd
      '0x40c806394d03d350420d13cd7d1de1c806f349560000000000000000000005f5', // qeth/weth
      '0x74e5e53056526b2609d82e85486005ef2a2db001000000000000000000000604', // TruMATIC-MATIC
      '0x35c5c8c7b77942f9d44b535fa590d8b503b2b00c00000000000000000000060d', // DUSD/sDAI
      '0xdd36fda7329291db8666540186cb36fcf0e36eac000000000000000000000612', // sDAI/DOLA
      '0x3de1b8854ec4f1a6e45fc56b8008abb14eb103fb00000000000000000000061f', // USDV/sDAI
      '0x9ca0ffcbae87a66a8f4604bfec724da04e2474ae000000000000000000000621', // USDV/USDC
      '0xa468570db143321bc034bbd74a6cc2694d15b252000000000000000000000629', // SWEEP-USDC-BPT
      '0xb9debddf1d894c79d2b2d09f819ff9b856fca55200000000000000000000062a', // weETH-WETH-BPT
      '0x50359088f666a9e70dc00b565ecd9f853a572c7c00000000000000000000062e', // osETH-ETH BPT
      '0xdacf5fa19b1f720111609043ac67a9818262850c000000000000000000000635', // osETH/wETH-BPT
      '0x05ff47afada98a98982113758878f9a8b9fdda0a000000000000000000000645', // weETH/rETH
      '0x88794c65550deb6b4087b7552ecf295113794410000000000000000000000648', // pxETH/wETH
      '0xb06bfbd7b50f80c8d9da57fc4cf5cbd5b3e2f14800000000000000000000064d', // pxETH/frxETH
      '0x49cbd67651fbabce12d1df18499896ec87bef46f00000000000000000000064a', // sDAI/3Pool
      '0xa2e9242163d54b536583368a01b5d232c95aa884000000000000000000000653', // ePENDLE/PENDLE
      '0x383e7859271b2d0589b013b6d944572a0a8be3cb000000000000000000000657', // DOLA-mkUSD BSP
      '0x596192bb6e41802428ac943d2f1476c1af25cc0e000000000000000000000659', // ezETH/WETH
      '0x7761b6e0daa04e70637d81f1da7d186c205c2ade00000000000000000000065d', // rsETH / ETHx
      '0xd3f8ab9c5928fdf53153e7757131ad7815fe3146000000000000000000000661', // vETH/wstETH
      '0xdedb11a6a23263469567c2881a9b9f8629ee0041000000000000000000000669', // svETH/wstETH
      '0x848a5564158d84b8a8fb68ab5d004fae11619a5400000000000000000000066a', // weETH/ezETH/rswETH
      '0x264062ca46a1322c2e6464471764089e01f22f1900000000000000000000066b', // sDOLA-DOLA BSP
      '0x09b03b7cbb19b3dae94f884cf60dbc3c99a3947b00000000000000000000066c', // pyUSD/sDOLA BSP
      '0xe3e0022d25194431a98e8bf5034d2617c96e1d44000000000000000000000670', // InstETH/wstETH
      '0x386ab93f2efe7270e35b6b3d031aacbbc1bcc8fc000000000000000000000673', // rsETH/wETH
      '0x6aa5a6b9257ca6e18b2da94e1a5fbe57ce2947ca00000000000000000000067b', // genETH/wstETH
      '0xb91159aa527d4769cb9faf3e4adb760c7e8c8ea700000000000000000000067c', // ETHx/wstETH
      '0x58aadfb1afac0ad7fca1148f3cde6aedf5236b6d00000000000000000000067f', // rsETH / WETH
      '0x63e0d47a6964ad1565345da9bfa66659f4983f02000000000000000000000681', // pufETH/wstETH
      '0x7e765515e8f8e6751bfc58937460eb4f4fe8e8a4000000000000000000000684', // mswETH/rswETH
      '0x51c72451eddfcc08aae540fd36434bb7ba340d33000000000000000000000683', // mstETH/wstETH
      '0xed0df9cd16d806e8a523805e53cf0c56e6db4d1d000000000000000000000687', // ShezUSD/sDAI
      '0xb819feef8f0fcdc268afe14162983a69f6bf179e000000000000000000000689', // sUSDe/USDC
    ],
  },
  Investment: {
    AllowList: [
      '0xccf5575570fac94cec733a58ff91bb3d073085c70002000000000000000000af', // iROBOT mainnet
      '0xe7b1d394f3b40abeaa0b64a545dbcf89da1ecb3f00010000000000000000009a', // Techemy mainnet
      '0x3b40d7d5ae25df2561944dd68b252016c4c7b2800001000000000000000000c2', // WSB-DEFI mainnet
    ],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [
      '0x8e6c196e201942246cef85718c5d3a5622518053000200000000000000000582', // 80LE/20WETH
      '0x67f117350eab45983374f4f83d275d8a5d62b1bf0001000000000000000004f2', // GRAIN/OATH/USDC/WETH
      '0x0fadd10f606554fec1574f28398469d98d68d297000200000000000000000503', // 80XEX/20USDC
      '0x42fbd9f666aacc0026ca1b88c94259519e03dd67000200000000000000000507', // COIL/USDC
      '0x639883476960a23b38579acfd7d71561a0f408cf000200000000000000000505', // STG/bb-a-usd
      '0x68e3266c9c8bbd44ad9dca5afbfe629022aee9fe000200000000000000000512', // wjonesaura
      '0xd278166dabaf26707362f7cfdd204b277fd2a4600002000000000000000004f6', // USH/WETH
      '0x08775ccb6674d6bdceb0797c364c2653ed84f3840002000000000000000004f0', // 3Pool/WETH
      '0x87a867f5d240a782d43d90b6b06dea470f3f8f22000200000000000000000516', // wseth/comp
      '0x8bd4a1e74a27182d23b98c10fd21d4fbb0ed4ba00002000000000000000004ed', // temple/dai
      '0xd689abc77b82803f22c49de5c8a0049cc74d11fd000200000000000000000524', // ush/unstheth
      '0x6987633f18ca0b4a10831331fcc57211941b6ba0000200000000000000000530', // 20AGI/80WETH
      '0xdf2c03c12442c7a0895455a48569b889079ca52a000200000000000000000538', // 20WETH/80ARCH
      '0x08cc92fedc1ce2d8525176a63fcff404450f2998000200000000000000000542', // 50BAO/50BAOUSD-LUSD
      '0x3035917be42af437cbdd774be26b9ec90a2bd677000200000000000000000543', // isnt/bb-inst-weth
      '0x1b6e13673f29688e27311b332af1527f1ebf1d28000200000000000000000546', // 80PSDN-20WETH
      '0x380aabe019ed2a9c2d632b51eddd30fd804d0fad000200000000000000000554', // R/wstETH
      '0x382dc5b2eca1c1308eb7e2b40c0f571afb899ac8000100000000000000000557', // multi-token
      '0x77b692c5ca2cceaeef4dcc959d6c3bd919710b6600020000000000000000055e', // 20USDC/80BREWSKI
      '0xb8e2cbb2455e80ad0eb536ae30a5290bdd7baa9100020000000000000000055f', // 20WETH/80BREWSKI
      '0x26c2b83fc8535deead276f5cc3ad9c1a2192e02700020000000000000000056b', // OHM/bbaDAI
      '0x18fdf15ff782e44c1f9b6c5846ff6b0f0004f6a2000200000000000000000560', // OHM/LUSD
      '0x3b9fb87f7d081ceddb1289258fa5660d955317b6000200000000000000000544', // baoeth/bao
      '0xd8721e92ba0f8235b375e9ec9a7b697ec4e2d6c6000200000000000000000575', // 80BIDS-20WETH
      '0x4729f67394030472e853cb61954fe784bfb36c3b000100000000000000000569', // 25WBTC-50USDC-25WETH
      '0x911fc393355fe415a667893d55fff6703c57c72e000200000000000000000574', // 50ETHx-50WETH
      '0x8334215586e93ee85e3f1a281eaf66e52015754d00020000000000000000057e', // 50WETH-50FIEF
      '0x4228290ee9cab692938ff0b4ba303fbcdb68e9f200020000000000000000057d', // bbaweth-ethx
      '0x3de27efa2f1aa663ae5d458857e731c129069f29000200000000000000000588', // wstETH-AAVE
      '0xb2b918f2d628b4c8ff237b0a1c6ac3bea222fedc00020000000000000000059c', // gho/ohm
      '0x7d98f308db99fdd04bbf4217a4be8809f38faa6400020000000000000000059b', // wstETH/ohm
      '0x158e0fbc2271e1dcebadd365a22e2b4dd173c0db0002000000000000000005a5', // idle/usdc
      '0x26cc136e9b8fd65466f193a8e5710661ed9a98270002000000000000000005ad', // 80RATE/20WETH
      '0xc88c76dd8b92408fe9bea1a54922a31e232d873c0002000000000000000005b2', // 80ASX-20WETH
      '0x6e5ba97e42e4bd036a9b785db964dc8aad1c699d0002000000000000000005b5', // 50USDC-50USDT
      '0x95f1a3ca4af4d08b9c42d65ef07b66e8222ed85a0002000000000000000005b6', // 50somm-50WETH
      '0xb841b062ea8ccf5c4cb78032e91de4ae875560420002000000000000000005b7', // 50bb-s-DAI-50bb-a-USD
      '0x2e52c64fd319e380cdbcfc4577ea1fda558a32e40002000000000000000005ba', // 50bb-a-WETH-50YieldETH
      '0xcebb73246fd3f0ba3e42a4ba6ff6b40d0cd02fb30001000000000000000005b8', // 20wstETH-50ETHx-20sfrxETH-10rETH
      '0xa9dd57145ca13a2f05199d85e3f2739af64784270002000000000000000005be', // 80TBTC-20WBTC
      '0xdb02f620a59f1b16146edd0e8dbeb27d94e9c25c0002000000000000000005c5', // 50STG-503POOL-BPT
      '0x064bcc35bfe023fe717a87574fae9333f98aae4d0002000000000000000005a6', // 80RATE/20WETH
      '0x39eb558131e5ebeb9f76a6cbf6898f6e6dce5e4e0002000000000000000005c8', // QI BPT
      '0x47e1cf97a0586367349a14306a65f54ba0b8f1b60002000000000000000005cd', // 20WETH-80ALTR
      '0x8ed9e70bfa17a1e2c4f8e561c8d0c2d1acc092fa0001000000000000000005ce', // 33LCNY-33LUSD-33WETH
      '0x759fabc513accd292ada967c4dd7bb94da39232e0002000000000000000005a8', // rate-weth
      '0x10f8ea49255e8a865ca4eda1630168c85cc8ee810002000000000000000005d3', // 50ELFI-50WETH
      '0x3ff3a210e57cfe679d9ad1e9ba6453a716c56a2e0002000000000000000005d5', // USDC/STG
      '0x034e2d995b39a88ab9a532a9bf0deddac2c576ea0002000000000000000005d1', // 80SD-20ETHx
      '0x0da692ac0611397027c91e559cfd482c4197e4030002000000000000000005c9', // 50bb-s-DAI-50bb-a-USDC
      '0x6f0ed6f346007563d3266de350d174a831bde0ca0001000000000000000005db', // KNC/WETH/USDC
      '0xf01b0684c98cd7ada480bfdf6e43876422fa1fc10002000000000000000005de', // ECLP-wstETH-wETH
      '0x6228f64d5ba8376652bfe7e36569d595347cf6fb0002000000000000000005df', // 80T-20TBTC
      '0xf7a826d47c8e02835d94fb0aa40f0cc9505cb1340002000000000000000005e0', // ECLP-wstETH-cbETH
      '0x5eeeef8ccf2aa28f6d2314a0566658b7cc8fe1360002000000000000000005e8', // ECLP-wstETH-rETH
      '0x38ca7131cfd8e3d734129416e2e4559c21eb6aca0002000000000000000005f1', // 20WETH-80UNIDX
      '0xb209468fc8c99360657d48238e1a7cf0b13362b60002000000000000000005f6', // 80SWIV-20WETH
      '0x94d6cb35f06abb71b87afe266b93b902b9aa2ed90002000000000000000005f7', // ECLP-USDP-GUSD
      '0xe074fbcc87e18615d6b099ee603bd37de562416b0002000000000000000005f8', // 50WAGMI-50ICE
      '0xe91888a1d08e37598867d213a4acb5692071bb3a0002000000000000000005f9', // 20R-80RAFT
      '0x05b1a35fdbc43849aa836b00c8861696edce8cc4000200000000000000000601', // 50mevETH-50WETH
      '0x1ecfae11f6c502c4cddf532e7ca3302c7dcca1970002000000000000000005fe', // ECLP-LUSD-crvUSD
      '0x127ecc2318d002664cc4515c9f2b22b09b6aea85000200000000000000000602', // ECLP-swETH-wstETH
      '0x150d969723bc8924dc667ba3254bacd967fad266000200000000000000000603', // NOVA-WETH
      '0x4114fd8554e63a9e0f09ca2480977883fea06430000200000000000000000607', // 50WETH-50USDT
      '0xd5a44704befd1cfcca67f7bc498a7654cc092959000200000000000000000609', // BAL-20WETH-80POP
      '0x317994cba902be6633de043a6bf05f4f08f4370200020000000000000000060b', // ECLP-LUSD-crvUSD
      '0x81e998523f02adf4679ff57fff8ca2b9d23a574700020000000000000000060a', // ECLP-USDP-GUSD
      '0x54ca50ee86616379420cc56718e12566aa75abbe000200000000000000000610', // 50wUSDM-50wstETH
      '0xb3b675a9a3cb0df8f66caf08549371bfb76a9867000200000000000000000611', // ECLP-mevETH-wETH
      '0x02ca8086498552c071451724d3a34caa3922b65a000200000000000000000613', // 80ROOT-20WETH
      '0x4da83bbe43235d600b914ec3dbde8b2eaa801869000200000000000000000614', // NOVA-WETH
      '0x271d57ce059780462f89800141a089008ca78d4a000200000000000000000616', // 80PRTC-20WETH
      '0xcf7b51ce5755513d4be016b0e28d6edeffa1d52a000200000000000000000617', // RDNT-WETH
      '0x5b3b589b45531a2f0ae203d5044782ba46aefc4c00010000000000000000061a', // 17DNT-17CVC-17OCEAN-17POLY-17USDC-17ENJ
      '0x58b645fa247b60f2cb896991fd8956146c9fcb4a00020000000000000000061d', // 80mevETH/20FOLD
      '0x7056c8dfa8182859ed0d4fb0ef0886fdf3d2edcf000200000000000000000623', // 80OETH/20WETH
      '0xce8d4ad8cce1240ecbc49385baf4c399a7f353f9000200000000000000000628', // BAL-20WETH-80VCX
      '0x82b8c7c6fb62d09cfd004309c1f353fb1a926edc000200000000000000000626', // EPOCH-LP
      '0x1cce5169bde03f3d5ad0206f6bd057953539dae600020000000000000000062b', // ECLP-GYD-sDAI
      '0xc2aa60465bffa1a88f5ba471a59ca0435c3ec5c100020000000000000000062c', // ECLP-GYD-USDC
      '0xfbfad5fa9e99081da6461f36f229b5cc88a64c6300020000000000000000062d', // ECLP-GYD-USDT
      '0x577a7f7ee659aa14dc16fd384b3f8078e23f1920000200000000000000000633', // BAL-20WETH-80VCX
      '0xb986fd52697f16be888bfad2c5bf12cd67ce834b000200000000000000000634', // stADS
      '0x82e80ce9d4890baf288b70ac548aa256599bf044000200000000000000000619', // 80MEVETH/20FOLD
      '0x470581ca95c071728e85a6e28a5b1754cd489be200020000000000000000063c', // 50OCEAN-50WETH
      '0x771fbbfcbd8ba252f7f1ee47c1a486bdb0b5bc6200020000000000000000063d', // 50RSR-50WETH
      '0xee3959fd00a0b996d801fc34b7ce566bd037f5f500020000000000000000063e', // 80MIMO-20WETH
      '0x6055689f452d2f9d86945f3597e1d47ee819254d000200000000000000000640', // 50mkUSD-50PRISMA
      '0xbb41e62ba1a743376119fa83ec6dd575ea796613000200000000000000000649', // 50dETH-50WETH
      '0x6aa6d7542310cdc75d1179b8729e1e6ec8d42bf100020000000000000000064b', // 50wstETH/50sDAI
      '0x7fd800d3ecd9e83322cfcb8d416eb93f56edb78100010000000000000000065b', // 50PAXG-25USDC-25WETH
      '0x99e7b15df044e1b62e2e1774fd4469d54d9409ac00020000000000000000065a', // ECLP-GHO-USDC
      '0x69d6e9636df140440eca285acc1f6c097aa57beb00020000000000000000065c', // B-KAI-WETH-WEIGHTED
      '0x0560e7e02cd054055d535c3c2b59baf565c6899b00020000000000000000065f', // B-kaiAURA-AURA-WEIGHTED
      '0x98b76fb35387142f97d601a297276bb152ae8ab0000200000000000000000662', // 20WETH-80ONDO
      '0xaa7a70070e7495fe86c67225329dbd39baa2f63b000200000000000000000663', // ECLP-GHO-GYD
      '0xa1e81ac3450edc5d0fa9c22addf7350f6a054daa000200000000000000000666', // VEC/pSVEC
      '0xbb5820734d6d1623c1a8b39c848bcfb1417bac19000200000000000000000667', // 50wstETH-50rETH
      '0x767cb26e38bebddfd857d4ad426d8ff5bc0fac2f00020000000000000000066e', // ECLP-pxETH-frxETH
      '0x3a2819b07981234f825e952f32cf977db5edbf7c00020000000000000000066f', // 80UNION-20wstETH
      '0xff42a9af956617e4c3532ef2fc7567465efe4909000200000000000000000672', // 50KEP/50rsETH
      '0x0f9fdc4d883f6d85b68ce1b8b96aa706a3538e5b000200000000000000000675', // 80gVEC-20vETH
      '0x57766212638c425e9cb0c6d6e1683dda369c0fff000200000000000000000678', // 80GEM-20WETH
      '0x56bc9d9987edec2fc6e1990e27af4a0987b53096000200000000000000000686', // 99goldCOMP-1WETH
    ],
  },
  Factories: {
    '0xa5bf2ddf098bb0ef6d120c98217dd6b141c74ee0': 'oracleWeightedPool',
    '0x8e9aa87e45e92bad84d5f8dd1bff34fb92637de9': 'weightedPool',
    '0xc66ba2b6595d3613ccab350c886ace23866ede24': 'stablePool',
    '0x67d27634e44793fe63c467035e31ea8635117cd4': 'stablePool', // Metastable
    '0x751a0bc0e3f75b38e01cf25bfce7ff36de1c87de': 'liquidityBootstrappingPool', // Mainnet LBP
    '0x0f3e0c4218b7b0108a3643cfe9d3ec0d4f57c54e': 'liquidityBootstrappingPool', // Mainnet LBP (zero protocol fee)
    '0x48767f9f868a4a7b86a90736632f6e44c2df7fa9': 'managedPool', // Mainnet Managed
    '0xb08e16cfc07c684daa2f93c70323badb2a6cbfd2': 'boostedPool', // mainnet stablephantom
    '0x8df6efec5547e31b0eb7d1291b511ff8a2bf987c': 'stablePool', // stable pool v2
    '0xf9ac7b9df2b3454e841110cce5550bd5ac6f875f': 'composableStablePool', // ComposableStable
    '0xfada0f4547ab2de89d1304a668c39b3e09aa7c76': 'composableStablePool', // ComposableStable v4
    '0xcc508a455f5b0073973107db6a878ddbdab957bc': 'weightedPool', // weighted pool v2
    '0xdba127fbc23fb20f5929c546af220a991b5c6e01': 'composableStablePool', // ComposableStable v2
    '0x5dd94da3644ddd055fcf6b3e1aa310bb7801eb8b': 'weightedPool', // weighted pool v3
    '0x81fe9e5b28da92ae949b705dfdb225f7a7cc5134': 'fx', // fx
    '0x897888115ada5773e02aa29f775430bfb5f34c51': 'weightedPool', // weighted pool v4
    '0x5f43fba61f63fa6bff101a0a0458cea917f6b347': 'eulerLinear',
    '0xdb8d758bcb971e482b2c45f7f8a7740283a1bd3a': 'composableStablePool', // ComposableStable v5
    '0x412a5b2e7a678471985542757a6855847d4931d5': 'gyroE',
    '0x579653927bf509b361f6e3813f5d4b95331d98c9': 'gyroE',
    '0xfb23bc0d2629268442cd6521cf4170698967105f': 'fx',
  },
  Stakable: {
    VotingGaugePools: [
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000063',
      '0x072f14b85add63488ddad88f855fda4a99d6ac9b000200000000000000000027',
      '0x0b09dea16768f0799065c475be02919503cb2a3500020000000000000000001a',
      '0x186084ff790c65088ba694df11758fae4943ee9e000200000000000000000013',
      '0x1e19cf2d73a72ef1332c882f20534b6519be0276000200000000000000000112',
      '0x27c9f71cc31464b906e0006d4fcbc8900f48f15f00020000000000000000010f',
      '0x32296969ef14eb0c6d29669c550d4a0449130230000200000000000000000080',
      '0x350196326aeaa9b98f1903fb5e8fc2686f85318c000200000000000000000084',
      '0x3e5fa9518ea95c3e533eb377c001702a9aacaa32000200000000000000000052',
      '0x51735bdfbfe3fc13dea8dc6502e2e958989429610002000000000000000000a0',
      '0x5d66fff62c17d841935b60df5f07f6cf79bd0f4700020000000000000000014c',
      '0x5f7fa48d765053f8dd85e052843e12d23e3d7bc50002000000000000000000c0',
      '0x702605f43471183158938c1a3e5f5a359d7b31ba00020000000000000000009f',
      '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe',
      '0x7edde0cb05ed19e03a9a47cd5e53fc57fde1c80c0002000000000000000000c8',
      '0x8f4205e1604133d1875a3e771ae7e4f2b086563900020000000000000000010e',
      '0x90291319f1d4ea3ad4db0dd8fe9e12baf749e84500020000000000000000013c',
      '0x96646936b91d6b9d7d0c47c496afbf3d6ec7b6f8000200000000000000000019',
      '0x96ba9025311e2f47b840a1f68ed57a3df1ea8747000200000000000000000160',
      '0xa02e4b3d18d4e6b8d18ac421fbc3dfff8933c40a00020000000000000000004b',
      '0xa6f548df93de924d73be7d25dc02554c6bd66db500020000000000000000000e',
      '0xbaeec99c90e3420ec6c1e7a769d2a856d2898e4d00020000000000000000008a',
      '0xbf96189eee9357a95c7719f4f5047f76bde804e5000200000000000000000087',
      '0xe2469f47ab58cf9cf59f9822e3c5de4950a41c49000200000000000000000089',
      '0xe99481dc77691d8e2456e5f3f61c1810adfc1503000200000000000000000018',
      '0xec60a5fef79a92c741cb74fdd6bfc340c0279b01000200000000000000000015',
      '0xedf085f65b4f6c155e13155502ef925c9a756003000200000000000000000123',
      '0xefaa1604e82e1b3af8430b90192c1b9e8197e377000200000000000000000021',
      '0xf4c0dd9b82da36c07605df83c8a416f11724d88b000200000000000000000026',
      '0xf5aaf7ee8c39b651cebf5f1f50c10631e78e0ef9000200000000000000000069',
      '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56000000000000000000000066',
      '0x17ddd9646a69c9445cd8a9f921d4cd93bf50d108000200000000000000000159',
      '0x92762b42a06dcdddc5b7362cfb01e631c4d44b40000200000000000000000182',
      '0xde8c195aa41c11a0c4787372defbbddaa31306d2000200000000000000000181',
      '0xc45d42f801105e861e86658648e3678ad7aa70f900010000000000000000011e',
      '0x2d344a84bac123660b021eebe4eb6f12ba25fe8600020000000000000000018a',
      '0xb460daa847c45f1c4a41cb05bfb3b51c92e41b36000200000000000000000194',
      '0x5122e01d819e58bb2e22528c0d68d310f0aa6fd7000200000000000000000163',
      '0x851523a36690bf267bbfec389c823072d82921a90002000000000000000001ed',
      '0xe8cc7e765647625b95f59c15848379d10b9ab4af0002000000000000000001de',
      '0x85370d9e3bb111391cc89f6de344e801760461830002000000000000000001ef',
      '0xa7ff759dbef9f3efdd1d59beee44b966acafe214000200000000000000000180',
      '0x3f7c10701b14197e2695dec6428a2ca4cf7fc3b800020000000000000000023c',
      '0x2d011adf89f0576c9b722c28269fcb5d50c2d17900020000000000000000024d',
      '0x178e029173417b1f9c8bc16dcec6f697bc32374600000000000000000000025d',
      '0xcfca23ca9ca720b6e98e3eb9b6aa0ffc4a5c08b9000200000000000000000274',
      '0x3dd0843a028c86e0b760b1a76929d1c5ef93a2dd000200000000000000000249',
      '0x0578292cb20a443ba1cde459c985ce14ca2bdee5000100000000000000000269',
      '0x8eb6c82c3081bbbd45dcac5afa631aac53478b7c000100000000000000000270',
      '0x1b65fe4881800b91d4277ba738b567cbb200a60d0002000000000000000002cc',
      '0x99a14324cfd525a34bbc93ac7e348929909d57fd00020000000000000000030e',
      '0x9b532ab955417afd0d012eb9f7389457cd0ea712000000000000000000000338',
      '0x48607651416a943bf5ac71c41be1420538e78f87000200000000000000000327',
      '0x6a5ead5433a50472642cd268e584dafa5a394490000200000000000000000366',
      '0x0fd5663d4893ae0d579d580584806aadd2dd0b8b000200000000000000000367',
      '0x441b8a1980f2f2e43a9397099d15cc2fe6d3625000020000000000000000035f',
      '0xf3aeb3abba741f0eece8a1b1d2f11b85899951cb000200000000000000000351',
      '0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d',
      '0x496ff26b76b8d23bbc6cf1df1eee4a48795490f7000200000000000000000377',
      '0x5b3240b6be3e7487d61cd1afdfc7fe4fa1d81e6400000000000000000000037b',
      '0x334c96d792e4b26b841d28f53235281cec1be1f200020000000000000000038a',
      '0x25accb7943fd73dda5e23ba6329085a3c24bfb6a000200000000000000000387',
      '0xe340ebfcaa544da8bb1ee9005f1a346d50ec422e000200000000000000000396',
      '0xae7bfd6fa54259fc477879712eebe34164d3a84f000200000000000000000376',
      '0x4ce0bd7debf13434d3ae127430e9bd4291bfb61f00020000000000000000038b',
      '0x8e85e97ed19c0fa13b2549309965291fbbc0048b0000000000000000000003ba',
      '0x173063a30e095313eee39411f07e95a8a806014e0002000000000000000003ab',
      '0x8167a1117691f39e05e9131cfa88f0e3a620e96700020000000000000000038c',
      '0x798b112420ad6391a4129ac25ef59663a44c88bb0002000000000000000003f4',
      '0x5512a4bbe7b3051f92324bacf25c02b9000c4a500001000000000000000003d7',
      '0x4edcb2b46377530bc18bb4d2c7fe46a992c73e100000000000000000000003ec',
      '0xd1ec5e215e8148d76f4460e4097fd3d5ae0a35580002000000000000000003d3',
      '0x76fcf0e8c7ff37a47a799fa2cd4c13cde0d981c90002000000000000000003d2',
      '0xc9c5ff67bb2fae526ae2467c359609d6bcb4c5320000000000000000000003cc',
      '0x9c6d47ff73e0f5e51be5fd53236e3f595c5793f200020000000000000000042c',
      '0xff4ce5aaab5a627bf82f4a571ab1ce94aa365ea6000200000000000000000426',
      '0xd590931466cdd6d488a25da1e89dd0539723800c00020000000000000000042b',
      '0x8a34b5ad76f528bfec06c80d85ef3b53da7fc30000020000000000000000043e',
      '0xdb0cbcf1b8282dedc90e8c2cefe11041d6d1e9f0000200000000000000000431',
      '0xe4010ef5e37dc23154680f23c4a0d48bfca91687000200000000000000000432',
      '0xb08885e6026bab4333a80024ec25a1a3e1ff2b8a000200000000000000000445',
      '0x384f67aa430376efc4f8987eabf7f3f84eb9ea5d00020000000000000000043d',
      '0xad0e5e0778cac28f1ff459602b31351871b5754a0002000000000000000003ce',
      '0x00c2a4be503869fa751c2dbcb7156cc970b5a8da000000000000000000000477',
      '0x959216bb492b2efa72b15b7aacea5b5c984c3cca000200000000000000000472',
      '0x50cf90b954958480b8df7958a9e965752f62712400000000000000000000046f',
      '0xa3c500969accb3d8df08cba313c120818fe0ed9d000200000000000000000471',
      '0x831261f44931b7da8ba0dcc547223c60bb75b47f000200000000000000000460',
      '0xfd1cf6fd41f229ca86ada0584c63c49c3d66bbc9000200000000000000000438',
      '0x5aee1e99fe86960377de9f88689616916d5dcabe000000000000000000000467',
      '0x9f9d900462492d4c21e9523ca95a7cd86142f298000200000000000000000462',
      '0x1ee442b5326009bb18f2f472d3e0061513d1a0ff000200000000000000000464',
      '0x5f1f4e50ba51d723f12385a8a9606afc3a0555f5000200000000000000000465',
      '0x4fd4687ec38220f805b6363c3c1e52d0df3b5023000200000000000000000473',
      '0xa718042e5622099e5f0ace4e7122058ab39e1bbe000200000000000000000475',
      '0xb5e3de837f869b0248825e0175da73d4e8c3db6b000200000000000000000474',
      '0x133d241f225750d2c92948e464a5a80111920331000000000000000000000476',
      '0x36be1e97ea98ab43b4debf92742517266f5731a3000200000000000000000466',
      '0x99c88ad7dc566616548adde8ed3effa730eb6c3400000000000000000000049a',
      '0x20b156776114e8a801e9767d90c6ccccc8adf398000000000000000000000499',
      '0x15c1cdacd3da1e1c1304200b1beb080d50bbbc0f00020000000000000000045f',
      '0x483006684f422a9448023b2382615c57c5ecf18f000000000000000000000488',
      '0x60683b05e9a39e3509d8fdb9c959f23170f8a0fa000000000000000000000489',
      '0xd4f79ca0ac83192693bce4699d0c10c66aa6cf0f00020000000000000000047e',
      '0xf16aee6a71af1a9bc8f56975a4c2705ca7a782bc0002000000000000000004bb',
      '0xcaa052584b462198a5a9356c28bce0634d65f65c0000000000000000000004db',
      '0x779d01f939d78a918a3de18cc236ee89221dfd4e0000000000000000000004c7',
      '0x9cc64ee4cb672bc04c54b00a37e1ed75b2cc19dd0002000000000000000004c1',
      '0x79c58f70905f734641735bc61e45c19dd9ad60bc0000000000000000000004e7',
      '0xfebb0bbf162e64fb9d0dfe186e517d84c395f016000000000000000000000502',
      '0xe0fcbf4d98f0ad982db260f86cf28b49845403c5000000000000000000000504',
      '0xd278166dabaf26707362f7cfdd204b277fd2a4600002000000000000000004f6',
      '0x08775ccb6674d6bdceb0797c364c2653ed84f3840002000000000000000004f0',
      '0x639883476960a23b38579acfd7d71561a0f408cf000200000000000000000505',
      '0x8bd4a1e74a27182d23b98c10fd21d4fbb0ed4ba00002000000000000000004ed',
      '0x9001cbbd96f54a658ff4e6e65ab564ded76a543100000000000000000000050a',
      '0x87a867f5d240a782d43d90b6b06dea470f3f8f22000200000000000000000516',
      '0x04248aabca09e9a1a3d5129a7ba05b7f17de768400000000000000000000050e',
      '0x68e3266c9c8bbd44ad9dca5afbfe629022aee9fe000200000000000000000512',
      '0x0018c32d85d8aebea2efbe0b0f4a4eb9e4f1c8c900020000000000000000050c',
      '0x02d928e68d8f10c0358566152677db51e1e2dc8c00000000000000000000051e',
      '0xd689abc77b82803f22c49de5c8a0049cc74d11fd000200000000000000000524',
      '0x42fbd9f666aacc0026ca1b88c94259519e03dd67000200000000000000000507',
      '0x3e953c6bf97284f736c5f95b3c3be8f4e48075f4000200000000000000000522',
      '0x793f2d5cd52dfafe7a1a1b0b3988940ba2d6a63d0000000000000000000004f8',
      '0x7e9afd25f5ec0eb24d7d4b089ae7ecb9651c8b1f000000000000000000000511',
      '0x2e848426aec6dbf2260535a5bea048ed94d9ff3d000000000000000000000536',
      '0xec3626fee40ef95e7c0cbb1d495c8b67b34d398300000000000000000000053d',
      '0xeb567dde03f3da7fe185bdacd5ab495ab220769d000000000000000000000548',
      '0xdf2c03c12442c7a0895455a48569b889079ca52a000200000000000000000538',
      '0x380aabe019ed2a9c2d632b51eddd30fd804d0fad000200000000000000000554',
      '0x20a61b948e33879ce7f23e535cc7baa3bc66c5a9000000000000000000000555',
      '0xfcf77141908aa22bfeac216123c5feb2531f373e00000000000000000000054a',
      '0xdfe6e7e18f6cc65fa13c8d8966013d4fda74b6ba000000000000000000000558',
      '0x1a44e35d5451e0b78621a1b3e7a53dfaa306b1d000000000000000000000051b',
      '0x18fdf15ff782e44c1f9b6c5846ff6b0f0004f6a2000200000000000000000560',
      '0x481c5fc05d63a58aa2f0f2aa417c021b5d419cb200000000000000000000056a',
      '0x26c2b83fc8535deead276f5cc3ad9c1a2192e02700020000000000000000056b',
      '0xc5dc1316ab670a2eed5716d7f19ced321191f38200000000000000000000056e',
      '0xbfce47224b4a938865e3e2727dc34e0faa5b1d82000000000000000000000527',
      '0x156c02f3f7fef64a3a9d80ccf7085f23cce91d76000000000000000000000570',
      '0x9d7f992c900fbea0ec314bdd71b7cc1becf76a33000200000000000000000573',
      '0x4cbde5c4b4b53ebe4af4adb85404725985406163000000000000000000000595',
      '0xbf2ef8bdc2fc0f3203b3a01778e3ec5009aeef3300000000000000000000058d',
      '0x3fa8c89704e5d07565444009e5d9e624b40be813000000000000000000000599',
      '0x7d98f308db99fdd04bbf4217a4be8809f38faa6400020000000000000000059b',
      '0xb2b918f2d628b4c8ff237b0a1c6ac3bea222fedc00020000000000000000059c',
      '0xc2b021133d1b0cf07dba696fd5dd89338428225b000000000000000000000598',
      '0x9a172e1cb0e99f7e6dcc4c52e4655e8f337d5c0000000000000000000000059a',
      '0x42ed016f826165c2e5976fe5bc3df540c5ad0af700000000000000000000058b',
      '0xc443c15033fcb6cf72cc24f1bda0db070ddd9786000000000000000000000593',
      '0x41503c9d499ddbd1dcdf818a1b05e9774203bf46000000000000000000000594',
      '0xd7edb56f63b2a0191742aea32df1f98ca81ed9c600000000000000000000058e',
      '0x9b692f571b256140a39a34676bffa30634c586e100000000000000000000059d',
      '0xdd59f89b5b07b7844d72996fc9d83d81acc8219600000000000000000000059e',
      '0xb54e6aadbf1ac1a3ef2a56e358706f0f8e320a0300000000000000000000059f',
      '0x4c81255cc9ed7062180ea99962fe05ac0d57350b0000000000000000000005a3',
      '0x1bd2f176a812e312077bca87e37c08432bb09f3e0000000000000000000005a1',
      '0xae8535c23afedda9304b03c68a3563b75fc8f92b0000000000000000000005a0',
      '0xe8c56405bc405840154d9b572927f4197d110de10000000000000000000005a4',
      '0x158e0fbc2271e1dcebadd365a22e2b4dd173c0db0002000000000000000005a5',
      '0xe2d16b0a39f3fbb4389a0e8f1efcbecfb3d1e6e10000000000000000000005a7',
      '0x759fabc513accd292ada967c4dd7bb94da39232e0002000000000000000005a8',
      '0x616d4d131f1147ac3b3c3cc752bab8613395b2bb000200000000000000000584',
      '0x26cc136e9b8fd65466f193a8e5710661ed9a98270002000000000000000005ad',
      '0xe4e72f872c4048925a78e1e6fddac411c9ae348a0000000000000000000005bc',
      '0x3ff3a210e57cfe679d9ad1e9ba6453a716c56a2e0002000000000000000005d5',
      '0x93d199263632a4ef4bb438f1feb99e57b4b5f0bd0000000000000000000005c2',
      '0xe7e2c68d3b13d905bbb636709cf4dfd21076b9d20000000000000000000005ca',
      '0xbe19d87ea6cd5b05bbc34b564291c371dae967470000000000000000000005c4',
      '0xdb02f620a59f1b16146edd0e8dbeb27d94e9c25c0002000000000000000005c5',
      '0x37b18b10ce5635a84834b26095a0ae5639dcb7520000000000000000000005cb',
      '0xd6abbfd427398ea463b4f15f405624bab520db030000000000000000000005c6',
      '0xd5d99b7e9697ba8bb1da48f07ba81900c7572cea0000000000000000000005cc',
      '0x8ed9e70bfa17a1e2c4f8e561c8d0c2d1acc092fa0001000000000000000005ce',
      '0x8353157092ed8be69a9df8f95af097bbf33cb2af0000000000000000000005d9',
      '0x6ae5a7857aad26722cb02cc168e38c52e0e4e45d0000000000000000000005dd',
      '0x6f0ed6f346007563d3266de350d174a831bde0ca0001000000000000000005db',
      '0x034e2d995b39a88ab9a532a9bf0deddac2c576ea0002000000000000000005d1',
      '0x10f8ea49255e8a865ca4eda1630168c85cc8ee810002000000000000000005d3',
      '0x6228f64d5ba8376652bfe7e36569d595347cf6fb0002000000000000000005df',
      '0x47e1cf97a0586367349a14306a65f54ba0b8f1b60002000000000000000005cd',
      '0x70d5e3234f6329c1d5a26796dcf4e109d69a34880000000000000000000005e7',
      '0x40c806394d03d350420d13cd7d1de1c806f349560000000000000000000005f5',
      '0x127ecc2318d002664cc4515c9f2b22b09b6aea85000200000000000000000602',
      '0x02ca8086498552c071451724d3a34caa3922b65a000200000000000000000613',
      '0xcf7b51ce5755513d4be016b0e28d6edeffa1d52a000200000000000000000617',
      '0xb3b675a9a3cb0df8f66caf08549371bfb76a9867000200000000000000000611',
      '0x01536b22ea06e4a315e3daaf05a12683ed4dc14c0000000000000000000005fc',
      '0xa2e9242163d54b536583368a01b5d232c95aa884000000000000000000000653',
      '0x596192bb6e41802428ac943d2f1476c1af25cc0e000000000000000000000659',
      '0x383e7859271b2d0589b013b6d944572a0a8be3cb000000000000000000000657',
      '0x3932b187f440ce7703653b3908edc5bb7676c283000200000000000000000664',
      '0x99e7b15df044e1b62e2e1774fd4469d54d9409ac00020000000000000000065a',
      '0xaa7a70070e7495fe86c67225329dbd39baa2f63b000200000000000000000663',
      '0xd3f8ab9c5928fdf53153e7757131ad7815fe3146000000000000000000000661',
      '0x7761b6e0daa04e70637d81f1da7d186c205c2ade00000000000000000000065d',
      '0xdedb11a6a23263469567c2881a9b9f8629ee0041000000000000000000000669',
      '0x264062ca46a1322c2e6464471764089e01f22f1900000000000000000000066b',
      '0x848a5564158d84b8a8fb68ab5d004fae11619a5400000000000000000000066a',
      '0xff42a9af956617e4c3532ef2fc7567465efe4909000200000000000000000672',
      '0x09b03b7cbb19b3dae94f884cf60dbc3c99a3947b00000000000000000000066c',
      '0x4c4ed61d81f531dcb34ad4d498548917aba3d193000200000000000000000680',
      '0x6aa5a6b9257ca6e18b2da94e1a5fbe57ce2947ca00000000000000000000067b',
      '0xe3e0022d25194431a98e8bf5034d2617c96e1d44000000000000000000000670', // InstETH/wstETH
      '0x63e0d47a6964ad1565345da9bfa66659f4983f02000000000000000000000681',
      '0xb91159aa527d4769cb9faf3e4adb760c7e8c8ea700000000000000000000067c',
      '0xb819feef8f0fcdc268afe14162983a69f6bf179e000000000000000000000689',
    ],
    AllowList: [
      '0x54ca50ee86616379420cc56718e12566aa75abbe000200000000000000000610', // wusdm/wsteth
      '0x9ca0ffcbae87a66a8f4604bfec724da04e2474ae000000000000000000000621', // usdv/usdc
      '0xdacf5fa19b1f720111609043ac67a9818262850c000000000000000000000635', // osETH/WETH
      '0x35c5c8c7b77942f9d44b535fa590d8b503b2b00c00000000000000000000060d', // sdai/dusd
      '0x73f8e7a9a19e284a9ac85704af58454cfe75f059000200000000000000000631',
      '0x5f8b11995d7f95faa59ca6fd5ffa1c0dbbe0ec7b000200000000000000000630',
      '0x05ff47afada98a98982113758878f9a8b9fdda0a000000000000000000000645', // weETH/rETH
      '0xb06bfbd7b50f80c8d9da57fc4cf5cbd5b3e2f14800000000000000000000064d', // pxETH/frxETH
      '0x49cbd67651fbabce12d1df18499896ec87bef46f00000000000000000000064a', // sDAI/[USDC/USDT/DAI]
      '0x596192bb6e41802428ac943d2f1476c1af25cc0e000000000000000000000659', // ezETH/WETH
      '0xd3f8ab9c5928fdf53153e7757131ad7815fe3146000000000000000000000661', // vETH/wstETH
      '0xdedb11a6a23263469567c2881a9b9f8629ee0041000000000000000000000669', // svETH/wstETH
      '0x56bc9d9987edec2fc6e1990e27af4a0987b53096000200000000000000000686', // gold
    ],
  },
  Metadata: {
    '0x848a5564158d84b8a8fb68ab5d004fae11619a5400000000000000000000066a': {
      points: [
        {
          protocol: Protocol.Swell,
          multiple: '3',
          description:
            'LPs in this pool get their share of: <ul class="list-disc list-inside ml-2"><li>1x on the total TVL of the pool.</li><li>This is around 3x the amount of rswETH if it comprises around a third of the pool. </li></ul>',
        },
        {
          protocol: Protocol.Renzo,
          multiple: '1',
          description:
            'LPs in this pool get their share of 1x on the amount of ezETH held in the pool.',
        },
        {
          protocol: Protocol.Eigenlayer,
          multiple: '1',
          description:
            'LPs in this pool get their share of Eigenlayer points depending on assets deposited in the pool.',
        },
      ],
    },
    '0x596192bb6e41802428ac943d2f1476c1af25cc0e000000000000000000000659': {
      points: [
        {
          protocol: Protocol.Renzo,
          multiple: '4',
          description:
            'LPs in this pool earn 2x ezPoints on the TVL of the pool (WETH + ezETH)',
        },
        {
          protocol: Protocol.Eigenlayer,
          multiple: '1',
          description:
            'LPs in this pool get their share of 1x on the amount of ezETH held in the pool.',
        },
      ],
    },
    '0x7761b6e0daa04e70637d81f1da7d186c205c2ade00000000000000000000065d': {
      points: [
        {
          protocol: Protocol.Kelp,
          multiple: '2',
          expiryTimestamp: 1711843200, // 2024-03-31 00:00:00
          description: `LPs earn 2x miles until March 31st. The Miles boost increases rewards based on the total pool capital, not just rsETH. Your daily Kelp Miles value is calculated by multiplying the effective rsETH balance by 10,000 times the boost value. Your Miles are then distributed based on your share of the liquidity pool.`,
        },
      ],
    },
    '0x58aadfb1afac0ad7fca1148f3cde6aedf5236b6d00000000000000000000067f': {
      points: [
        {
          protocol: Protocol.Kelp,
          multiple: '3',
          expiryTimestamp: 1714431600, // 2024-04-30 00:00:00
          description:
            'LPs earn 3x miles until April 30th. The Miles boost increases rewards based on the total pool capital, not just rsETH. Your daily Kelp Miles value is calculated by multiplying the effective rsETH balance by 10,000 times the boost value. Your Miles are then distributed based on your share of the liquidity pool.',
        },
        {
          protocol: Protocol.Eigenlayer,
          multiple: '1',
          description:
            'LPs in this pool get their share of Eigenlayer points depending on assets deposited in the pool.',
        },
      ],
    },
    '0xc2aa60465bffa1a88f5ba471a59ca0435c3ec5c100020000000000000000062c': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '5',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0xfbfad5fa9e99081da6461f36f229b5cc88a64c6300020000000000000000062d': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '5',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0x1cce5169bde03f3d5ad0206f6bd057953539dae600020000000000000000062b': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '5',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0xaa7a70070e7495fe86c67225329dbd39baa2f63b000200000000000000000663': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '5',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0x7f2b3b7fbd3226c5be438cde49a519f442ca2eda00020000000000000000067d': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '5',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0xf01b0684c98cd7ada480bfdf6e43876422fa1fc10002000000000000000005de': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '1',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0x127ecc2318d002664cc4515c9f2b22b09b6aea85000200000000000000000602': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '1',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
        {
          protocol: Protocol.Swell,
          multiple: '1',
          description:
            'LPs in this pool get their share of: <ul class="list-disc list-inside ml-2"><li>1x on the total TVL of the pool.</li><li>This is around 3x the amount of rswETH if it comprises around a third of the pool. </li></ul>',
        },
      ],
    },
    '0x8bb826afc0ff7d2c034a2883f4c461ffd238e1c300020000000000000000012b': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '1',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0xf6394a1ebabf706eb471ff09cef357cd2ff6bd61000200000000000000000e3f': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '1',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0xd25c02e5704e87fa7ca2727730e7ca6cc733339e000200000000000000000519': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '1',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
    '0x125bc5a031b2db6733bfa35d914ffa428095978b000200000000000000000514': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '1',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
  },
  Deep: [
    '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe', // bb-a-USD1 (mainnet)
    '0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d', // bb-a-USD2 (mainnet)
    // '0x25accb7943fd73dda5e23ba6329085a3c24bfb6a000200000000000000000387', // wstETH/bb-a-USD (mainnet)
    '0x5b3240b6be3e7487d61cd1afdfc7fe4fa1d81e6400000000000000000000037b', // dola/bb-a-USD (mainnet)
    // '0x4ce0bd7debf13434d3ae127430e9bd4291bfb61f00020000000000000000038b', // STG/bba-usd (mainnet)
    // '0x334c96d792e4b26b841d28f53235281cec1be1f200020000000000000000038a', // rETH/bba-usd (mainnet)
    // '0x53bc3cba3832ebecbfa002c12023f8ab1aa3a3a0000000000000000000000411', // TUSD/bb-a-usd (mainnet)
    '0x4c8d2e60863e8d7e1033eda2b3d84e92a641802000000000000000000000040f', // FRAX/aave-usdc (mainnet)
    '0x5aee1e99fe86960377de9f88689616916d5dcabe000000000000000000000467', // wstETH/sfrxETH/rETH (mainnet)
    // '0x50cf90b954958480b8df7958a9e965752f62712400000000000000000000046f', // euler usd (mainnet)
    // '0x133d241f225750d2c92948e464a5a80111920331000000000000000000000476', // dola/bb-e-usd (mainnet)
    // '0x00c2a4be503869fa751c2dbcb7156cc970b5a8da000000000000000000000477', // euler frax/euler usdc (mainnet)
    // '0x483006684f422a9448023b2382615c57c5ecf18f000000000000000000000488', // tusd/bb-e-usd (mainnet)
    '0x60683b05e9a39e3509d8fdb9c959f23170f8a0fa000000000000000000000489', // bb-i-usd (mainnet)
    // '0xb5e3de837f869b0248825e0175da73d4e8c3db6b000200000000000000000474', // reth/bb-e-usd (mainnet)
    // '0xa718042e5622099e5f0ace4e7122058ab39e1bbe000200000000000000000475', // temple/bb-e-usd (mainnet)
    // '0x4fd4687ec38220f805b6363c3c1e52d0df3b5023000200000000000000000473', // wsteth/bb-e-usd (mainnet)
    '0x959216bb492b2efa72b15b7aacea5b5c984c3cca000200000000000000000472', // stakedape/wsteth (mainnet)
    '0x99c88ad7dc566616548adde8ed3effa730eb6c3400000000000000000000049a', // bb-g-usd (mainnet)
    '0x3f7a7fd7f214be45ec26820fd01ac3be4fc75aa70002000000000000000004c5', // stg/bbeusd
    '0xcaa052584b462198a5a9356c28bce0634d65f65c0000000000000000000004db', // ma usdc/usdt/dai (morpho boosted)
    '0x779d01f939d78a918a3de18cc236ee89221dfd4e0000000000000000000004c7', // sturdy usd
    '0x639883476960a23b38579acfd7d71561a0f408cf000200000000000000000505', // stg/bb-a-usd
    '0x08775ccb6674d6bdceb0797c364c2653ed84f3840002000000000000000004f0', // weth 3pool
    '0xe0fcbf4d98f0ad982db260f86cf28b49845403c5000000000000000000000504', // bb-a-weth/wsteth
    '0xfebb0bbf162e64fb9d0dfe186e517d84c395f016000000000000000000000502', // aave usd v3
    '0x02d928e68d8f10c0358566152677db51e1e2dc8c00000000000000000000051e', // sweth/bb-a-weth
    '0x04248aabca09e9a1a3d5129a7ba05b7f17de768400000000000000000000050e', // bb-a-weth / qeth
    '0x9001cbbd96f54a658ff4e6e65ab564ded76a543100000000000000000000050a', // cbeth / bb-a-weth
    '0x3035917be42af437cbdd774be26b9ec90a2bd677000200000000000000000543', // inst / bb-inst-weth
    '0xf57c794f42da72b38c8f610ff3b5e8502e48cbde00000000000000000000055c', // DOLA/bb-a-usd
    '0x481c5fc05d63a58aa2f0f2aa417c021b5d419cb200000000000000000000056a', // reth/bb-a-weth
    '0x26c2b83fc8535deead276f5cc3ad9c1a2192e02700020000000000000000056b', // OHM/bbaDAI
    '0xc5dc1316ab670a2eed5716d7f19ced321191f38200000000000000000000056e', // wstETH/morpho weth
    '0x4228290ee9cab692938ff0b4ba303fbcdb68e9f200020000000000000000057d', // bbaweth-ethx
    '0xbf2ef8bdc2fc0f3203b3a01778e3ec5009aeef3300000000000000000000058d', // R/bbsDAI
    '0x4cbde5c4b4b53ebe4af4adb85404725985406163000000000000000000000595', // ETHx/WETH
    '0xc443c15033fcb6cf72cc24f1bda0db070ddd9786000000000000000000000593', // bb-a-USD
    '0x41503c9d499ddbd1dcdf818a1b05e9774203bf46000000000000000000000594', // wstETH-bb-a-WETH-BPT
    '0xd7edb56f63b2a0191742aea32df1f98ca81ed9c600000000000000000000058e', // B-wstETH/bb-ma3-weth
    '0xc2b021133d1b0cf07dba696fd5dd89338428225b000000000000000000000598', // gho/bb-a-usd
    '0xe2d16b0a39f3fbb4389a0e8f1efcbecfb3d1e6e10000000000000000000005a7', // dusd-bb-a-usd
    '0x9b692f571b256140a39a34676bffa30634c586e100000000000000000000059d', // bb-i-USD
    '0x4c81255cc9ed7062180ea99962fe05ac0d57350b0000000000000000000005a3', // cbETH-bb-a-WETH
    '0x1bd2f176a812e312077bca87e37c08432bb09f3e0000000000000000000005a1', // qETH-bb-a-WETH
    '0xae8535c23afedda9304b03c68a3563b75fc8f92b0000000000000000000005a0', // swETH-bb-a-WETH
    '0xe8c56405bc405840154d9b572927f4197d110de10000000000000000000005a4', // rETH/bbaWETH
    '0xbe19d87ea6cd5b05bbc34b564291c371dae967470000000000000000000005c4', // GHO-3POOL-BPT
    '0xd5d99b7e9697ba8bb1da48f07ba81900c7572cea0000000000000000000005cc', // DUSD-3POOL-BPT
  ],
  BoostedApr: [
    '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb2', // bb-a-USD1 (mainnet)
    '0xa13a9247ea42d743238089903570127dda72fe44', // bb-a-USD2 (mainnet)
    '0x25accb7943fd73dda5e23ba6329085a3c24bfb6a', // wstETH/bb-a-USD (mainnet)
    '0x5b3240b6be3e7487d61cd1afdfc7fe4fa1d81e64', // dola/bb-a-USD (mainnet)
  ],
  DisabledJoins: [
    '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56000000000000000000000066',
    '0xad6a8c18b62eb914604ec1eec7fbcf132799fe090001000000000000000003f6',
    '0x5b3240b6be3e7487d61cd1afdfc7fe4fa1d81e6400000000000000000000037b',
    '0x851523a36690bf267bbfec389c823072d82921a90002000000000000000001ed',
    '0x50cf90b954958480b8df7958a9e965752f62712400000000000000000000046f', // Euler Boosted USD
    '0x00c2a4be503869fa751c2dbcb7156cc970b5a8da000000000000000000000477', // Euler USDC / Euler FRAX
    '0x483006684f422a9448023b2382615c57c5ecf18f000000000000000000000488', // Euler Boosted USD/TUSD
    '0xb5e3de837f869b0248825e0175da73d4e8c3db6b000200000000000000000474', // Euler Boosted USD/rETH
    '0xa718042e5622099e5f0ace4e7122058ab39e1bbe000200000000000000000475', // Euler Boosted USD/TEMPLE
    '0x4fd4687ec38220f805b6363c3c1e52d0df3b5023000200000000000000000473', // Euler Boosted USD/wstETH
    '0x133d241f225750d2c92948e464a5a80111920331000000000000000000000476', // Euler Boosted USD/dola
    ...CSP_ISSUE_POOL_IDS[Network.MAINNET],
  ],
  NewVersionAvailable: {
    '0x32296969ef14eb0c6d29669c550d4a0449130230000200000000000000000080': {
      newPool:
        '0x41503c9d499ddbd1dcdf818a1b05e9774203bf46000000000000000000000594',
      description: 'newVersion.Aave3',
      title: 'announcement',
    },
    '0x9c6d47ff73e0f5e51be5fd53236e3f595c5793f200020000000000000000042c': {
      newPool:
        '0x9001cbbd96f54a658ff4e6e65ab564ded76a543100000000000000000000050a',
      description: 'newVersion.Aave3',
      title: 'announcement',
    },
    '0x5aee1e99fe86960377de9f88689616916d5dcabe000000000000000000000467': {
      newPool:
        '0x42ed016f826165c2e5976fe5bc3df540c5ad0af700000000000000000000058b',
      description: 'newVersion.BalIncentives',
      title: 'announcement',
    },
  },
  Deprecated: {
    '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000063': {},
    '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe': {},
    '0x8e85e97ed19c0fa13b2549309965291fbbc0048b0000000000000000000003ba': {
      newPool:
        '0x5aee1e99fe86960377de9f88689616916d5dcabe000000000000000000000467',
    },
    '0xe340ebfcaa544da8bb1ee9005f1a346d50ec422e000200000000000000000396': {
      newPool:
        '0x1ee442b5326009bb18f2f472d3e0061513d1a0ff000200000000000000000464',
    },
    '0x6a5ead5433a50472642cd268e584dafa5a394490000200000000000000000366': {
      newPool:
        '0x5f1f4e50ba51d723f12385a8a9606afc3a0555f5000200000000000000000465',
    },
    '0x798b112420ad6391a4129ac25ef59663a44c88bb0002000000000000000003f4': {
      newPool:
        '0x36be1e97ea98ab43b4debf92742517266f5731a3000200000000000000000466',
    },
    '0x0fd5663d4893ae0d579d580584806aadd2dd0b8b000200000000000000000367': {
      newPool:
        '0x9f9d900462492d4c21e9523ca95a7cd86142f298000200000000000000000462',
    },
    '0x41503c9d499ddbd1dcdf818a1b05e9774203bf46000000000000000000000594': {
      newPool:
        '0x93d199263632a4ef4bb438f1feb99e57b4b5f0bd0000000000000000000005c2',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0xae8535c23afedda9304b03c68a3563b75fc8f92b0000000000000000000005a0': {
      newPool:
        '0xe7e2c68d3b13d905bbb636709cf4dfd21076b9d20000000000000000000005ca',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0xc2b021133d1b0cf07dba696fd5dd89338428225b000000000000000000000598': {
      newPool:
        '0xbe19d87ea6cd5b05bbc34b564291c371dae967470000000000000000000005c4',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0x639883476960a23b38579acfd7d71561a0f408cf000200000000000000000505': {
      newPool:
        '0xdb02f620a59f1b16146edd0e8dbeb27d94e9c25c0002000000000000000005c5',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0x4cbde5c4b4b53ebe4af4adb85404725985406163000000000000000000000595': {
      newPool:
        '0x37b18b10ce5635a84834b26095a0ae5639dcb7520000000000000000000005cb',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0x4c81255cc9ed7062180ea99962fe05ac0d57350b0000000000000000000005a3': {
      newPool:
        '0xd6abbfd427398ea463b4f15f405624bab520db030000000000000000000005c6',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0xe2d16b0a39f3fbb4389a0e8f1efcbecfb3d1e6e10000000000000000000005a7': {
      newPool:
        '0xd5d99b7e9697ba8bb1da48f07ba81900c7572cea0000000000000000000005cc',
      description: 'deprecatedPool.hasNewPool.description',
    },
  },
  GaugeMigration: {},
  BrandedRedirect: {
    FX: 'xave',
    Gyro2: 'gyro',
    Gyro3: 'gyro',
    GyroE: 'gyro',
  },
  ExitViaInternalBalance: [
    '0xd4e7c1f3da1144c9e2cfd1b015eda7652b4a439900000000000000000000046a', // bb-e-USDC
    '0x3c640f0d3036ad85afa2d5a9e32be651657b874f00000000000000000000046b', // bb-e-USDT
    '0xeb486af868aeb3b6e53066abc9623b1041b42bc000000000000000000000046c', // bb-e-DAI
  ],
  Issues: {
    [PoolWarning.PoolProtocolFeeVulnWarning]: [
      '0x5b3240b6be3e7487d61cd1afdfc7fe4fa1d81e6400000000000000000000037b',
    ],
    [PoolWarning.PoolOwnerVulnWarningGovernanceMigrate]: [
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000063',
    ],
    [PoolWarning.PoolOwnerVulnWarningGovernanceWithdraw]: [
      '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe',
    ],
    [PoolWarning.PoolOwnerVulnWarningGovernance]: [
      '0x9f19a375709baf0e8e35c2c5c65aca676c4c719100000000000000000000006e',
    ],
    [PoolWarning.PoolOwnerVulnWarningEcosystem]: [
      '0xe7b1d394f3b40abeaa0b64a545dbcf89da1ecb3f00010000000000000000009a',
      '0x3b40d7d5ae25df2561944dd68b252016c4c7b2800001000000000000000000c2',
      '0xccf5575570fac94cec733a58ff91bb3d073085c70002000000000000000000af',
    ],
    [PoolWarning.RenBTCWarning]: [
      '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc56000000000000000000000066',
      '0xad6a8c18b62eb914604ec1eec7fbcf132799fe090001000000000000000003f6',
    ],
    [PoolWarning.EulerBoostedWarning]: [
      '0x50cf90b954958480b8df7958a9e965752f62712400000000000000000000046f',
    ],
    [PoolWarning.EulerRecoveryModeWarning]: [
      '0x133d241f225750d2c92948e464a5a80111920331000000000000000000000476',
      '0x00c2a4be503869fa751c2dbcb7156cc970b5a8da000000000000000000000477',
      '0x483006684f422a9448023b2382615c57c5ecf18f000000000000000000000488',
      '0xb5e3de837f869b0248825e0175da73d4e8c3db6b000200000000000000000474',
      '0xa718042e5622099e5f0ace4e7122058ab39e1bbe000200000000000000000475',
      '0x4fd4687ec38220f805b6363c3c1e52d0df3b5023000200000000000000000473',
    ],
    [PoolWarning.CspPoolVulnWarning]: CSP_ISSUE_POOL_IDS[Network.MAINNET],
    [PoolWarning.FxPoolVulnWarning]: [
      '0x55bec22f8f6c69137ceaf284d9b441db1b9bfedc0002000000000000000003cd',
      '0x66bb9d104c55861feb3ec3559433f01f6373c9660002000000000000000003cf',
      '0xad0e5e0778cac28f1ff459602b31351871b5754a0002000000000000000003ce',
    ],
  },
};

export default pools;

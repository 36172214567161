import * as hekla from '@/assets/data/contracts/hekla.json';
import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: hekla.MerkleOrchard,
  multicall: '0xca11bde05977b3631167028862be2a173976ca11',
  authorizer: hekla.Authorizer,
  vault: hekla.Vault,
  weightedPoolFactory: hekla.WeightedPoolFactory,
  stablePoolFactory: hekla.StablePoolFactory,
  lidoRelayer: '',
  balancerHelpers: hekla.BalancerHelpers,
  batchRelayer: hekla.BalancerRelayer,
  veBAL: '',
  gaugeController: '',
  gaugeFactory: '',
  balancerMinter: '',
  tokenAdmin: '',
  veDelegationProxy: hekla.VotingEscrowDelegationProxy,
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '0x20a590c339a5a6ea0bd6817b832847067377ecfb',
  omniVotingEscrow: '',
  claimSubmission: '',
};

export default contracts;

import { Config } from '../types';
import contracts from './contracts';
import keys from './keys';
import pools from './pools';
import rateProviders from './rateProviders';
import tokenlists from './tokenlists';
import tokens from './tokens';

const config: Config = {
  key: '167009',
  chainId: 167009,
  chainName: 'Taiko Hekla',
  name: 'Taiko Hekla',
  shortName: 'Hekla',
  monorepoName: 'Hekla',
  slug: 'hekla',
  network: 'hekla',
  trustWalletNetwork: 'hekla',
  unknown: false,
  visibleInUI: true,
  testNetwork: true,
  publicRpc: `https://rpc.hekla.taiko.xyz`,
  rpc: `https://rpc.hekla.taiko.xyz`,
  ws: ``,
  explorer: 'https://explorer.hekla.taiko.xyz/',
  explorerName: 'Taiko Hekla Explorer',
  subgraph:
    'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/hekla/0.0.2/gn',
  poolsUrlV2:
    'https://storageapi.fleek.co/johngrantuk-team-bucket/poolsV2.json',
  subgraphs: {
    main: [
      'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/hekla/0.0.2/gn',
    ],
    aave: '',
    gauge: '',
    blocks: '',
  },
  bridgeUrl: '',
  supportsEIP1559: true,
  supportsElementPools: false, // hides featured ecosystems section under pool list
  blockTime: 11.9,
  nativeAsset: {
    name: 'ETH',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    symbol: 'ETH',
    decimals: 18,
    deeplinkId: 'eth',
    logoURI: 'tokens/eth.png',
    minTransactionBuffer: '0.05',
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'hekla',
      platformId: 'hekla',
    },
    apyVision: {
      networkName: 'eth',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 2,
    weight: 100,
  },
  tokenlists,
  rateProviders,
};

export default config;

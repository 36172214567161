import * as taiko from '@/assets/data/contracts/taiko.json';
import { Pools } from '@/types/pools';
import { AddressZero } from '@ethersproject/constants';

const pools: Pools = {
  IdsMap: {},
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [AddressZero],
  IncludedPoolTypes: [
    'Weighted',
    // 'Stable',
    // 'MetaStable',
    // 'LiquidityBootstrapping',
    // 'Investment',
    // 'StablePhantom',
    'ComposableStable',
    'FX',
    // 'EulerLinear',
    // 'Gyro2',
    // 'Gyro3',
    // 'GyroE',
  ],
  Stable: {
    AllowList: [
      '0x95a10e3e71b125e8ec6b9678e66006dcddbfb75d000000000000000000000003', // USDC:USDT:DAI
    ],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [],
  },
  Factories: {
    [taiko.WeightedPoolFactory]: 'weightedPool',
    [taiko.ComposableStablePoolFactory]: 'composableStablePool',
    //'<not created yet>': 'fx', // USDC_mock FXPoolDeployer
  },
  Stakable: {
    VotingGaugePools: [],
    AllowList: [],
  },
  Metadata: {},
  Deep: [],
  BoostedApr: [],
  DisabledJoins: [], // may contain pool ids with security issues
  NewVersionAvailable: {},
  Deprecated: {}, // for pool migration?
  GaugeMigration: {},
  BrandedRedirect: {},
  ExitViaInternalBalance: [],
  Issues: {},
};

export default pools;

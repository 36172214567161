import { TokenListURLMap } from '@/types/TokenList';

const tokenlists: TokenListURLMap = {
  Tanukix: {
    Allowlisted:
      'https://raw.githubusercontent.com/tanukix-dev/tokenlists/main/generated/balancer.tokenlist.json',
  },
  External: [],
};

export default tokenlists;

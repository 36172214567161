import { TokenConstants } from '../types';

const tokens: TokenConstants = {
  Popular: {
    Symbols: ['USDT', 'USDC'],
  },
  InitialSwapTokens: {
    input: '0x2def195713cf4a606b49d07e520e22c17899a736', // USDT
    output: '0x07d83526730c7438048d55a4fc0b850e2aab6f0b', // USDC
  },
  Addresses: {
    nativeAsset: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', // ETH
    wNativeAsset: '0xa51894664a773981c6c112c43ce576f315d5b1b6', // WETH
    WETH: '0xa51894664a773981c6c112c43ce576f315d5b1b6',
    BAL: '0x1af00782f74ddc4c7fcefe8752113084febcda45',
  },
};

export default tokens;
